import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBContainer, MDBView } from "mdbreact";
import React, { Component } from 'react';
import holidayDinners from './images/Rotating_Web_Banner_1140x370.jpg'
import holidayBakeSale from './images/BakeSale_Rotating_Web_Banner_1140x370_V1.jpg';
import holidaySingleMeals from './images/SU_SingleMeals_WebBanner_1140x370_V2.jpg';
// import diningGuide from './images/SU_DiningGuide_WebBanner_spring2024_V5.jpg';
import './style.css';

class SlideShow extends Component {

    render() {
        let base_slides = [
            holidaySingleMeals, 
        ];
        let base_urls = [
            'https://pciapps1.ba.arizona.edu/holiday-dinners-to-go/',
            // 'https://pciapps1.ba.arizona.edu/thanksgiving/bake-sale-form',
            // 'https://pciapps1.ba.arizona.edu/thanksgiving/a-la-carte-form',
        ];
        let base_alts = [
            'Holiday Dinners To-Go 2024', 
            // 'Thanksgiving Bake Sale 2024', 
            // 'Thanksgiving A La Carte 2024', 
        ];
        
        let imgs = [null, ...base_slides];
        let urls = [null, ...base_urls];
        let alts = [null, ...base_alts];

        return(
            <MDBContainer className="p-0">
                <MDBCarousel activeItem={1} length={imgs.length-1} showControls={true} showIndicators={true} interval={6000} className="z-depth-1">
                    <MDBCarouselInner>

                        {
                            imgs.map((img, index) => {
                                if (img != null){
                                    return (
                                        <MDBCarouselItem itemId={index}>
                                            <MDBView>
                                                <a href={urls[index]}><img className="d-block w-100" src={img} alt={alts[index]} /></a>
                                                {/* <MDBMask overlay="black-light" /> */}
                                            </MDBView>
                                            <MDBCarouselCaption>
                                                {/* <h3 className="h3-responsive">Light mask</h3>
                                                <p>First text</p> */}
                                            </MDBCarouselCaption>
                                        </MDBCarouselItem>
                                    )
                                }
                                else {
                                    return null
                                }
                            })
                        }

                    </MDBCarouselInner>
                </MDBCarousel>
            </MDBContainer>
        );
    }
}

export default SlideShow;